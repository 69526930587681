<template>
    <v-container fluid class="home">

        <div v-if="loading" class="pt-12">

            <v-row no-gutters>
                <v-col cols="12" justify="center" align="center" class="pt-12">
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>
            </v-row>
        </div>

        <div v-else class="mb-16">

            <div class="my-2 mb-4">

                <v-card class="mb-4 mild-shadow">
                    <v-card-text class="pa-0">
                        <v-list three-line>


                            <v-list-item>
                                <v-list-item-avatar rounded size="80">
                                    <v-img v-if="user.photo" :src="user.photo"></v-img>
                                    <v-icon color="#7E0DE7" v-else size="50">$vuetify.icons.account-icon</v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-bold" >{{ user.name }} <v-icon small color="secondary" class="mt-n1" @click="nameDialog=true;">mdi-pencil</v-icon></v-list-item-title>
                                    <v-list-item-subtitle class="text-caption"
                                        v-html="user.email"></v-list-item-subtitle>
                                    <v-list-item-subtitle class="text-caption"
                                        v-html="user.phone"></v-list-item-subtitle>
                                    <v-list-item-subtitle class="text-caption"
                                        v-html="user.state + ', ' + user.country"></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>

                                    <v-chip small class="text-caption font-weight-medium my-1" v-show="user.premium"
                                        color="primary">
                                        PREMIUM
                                    </v-chip>
                                    <v-chip dark small class="text-caption font-weight-medium my-1"
                                        v-show="user.deleted" color="grey">
                                        DELETED
                                    </v-chip>
                                    <v-chip small class="text-caption font-weight-medium my-1" v-show="user.banned"
                                        color="error">
                                        BANNED
                                    </v-chip>
                                    <v-btn small class="my-1" color="primary" outlined  @click="sendMessageDialog=true">
                                        Send Message
                                    </v-btn>
                                    <v-btn small class="my-1" color="green" outlined  v-show="!user.premium" @click="confirmPremiumOffer=true">
                                        Send 7-Day Premium Offer
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>

                        </v-list>

                    </v-card-text>
                </v-card>


                <v-tabs v-model="tab" grow active-class="active-tab-pill" color="transparent"
                    background-color="transparent">
                    <v-tab>Account</v-tab>
                    <v-tab v-if="user.pets && user.pets.length > 0">Pets</v-tab>
                    <v-tab v-if="user.preferences && user.preferences.length > 0">Preferences</v-tab>
                    <v-tab>Subscription</v-tab>
                    <v-tab>Conversations</v-tab>
                    <v-tab>Notifications</v-tab>
                    <v-tab>Reportings</v-tab>
                    <v-tab>Questionnaire</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" class="mt-4 full-height-tab">
                    <v-tab-item>

                        <v-row no-gutters>
                            <v-col cols="12" md="6">

                                <v-card class="mild-shadow ma-4">
                                    <v-card-text>
                                        <v-row no-gutters>
                                            <v-col><div class="text-overline grey--text text--lighten-1">Account</div></v-col>
                                            <!-- <v-col class="text-right"><v-btn small  color="primary" @click="nameDialog = true">
                                        Edit
                                    </v-btn></v-col> -->
                                        </v-row>


                                        <v-row>
                                            <v-col>Email Verified</v-col>
                                            <v-col v-if="user.email_verified"><v-icon color="success"
                                                    @click="setInformation('user','email',false)">mdi-checkbox-marked</v-icon> <span class="text-caption">{{
                                                        user.email_verified_at | moment("D MMM YY, h:mm a") }}</span></v-col>
                                            <v-col v-else><v-icon color="grey"
                                                    @click="setInformation('user','email',true)">mdi-checkbox-blank-outline</v-icon> <span>{{
                                                        user.email_code }}</span></v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>Phone Verified</v-col>
                                            <v-col v-if="user.phone_verified"><v-icon color="success"
                                                    @click="setInformation('user','phone',false)">mdi-checkbox-marked</v-icon>
                                                </v-col>
                                            <v-col v-else><v-icon color="grey"
                                                    @click="setInformation('user','phone',true)">mdi-checkbox-blank-outline</v-icon> </v-col>
                                        </v-row>
                                      <v-row>
                                          <v-col>Video Verified</v-col>
                                          <v-col v-if="user.video_verified"><v-icon color="success"
                                              @click="setInformation('user','video',false)">mdi-checkbox-marked</v-icon>
                                          </v-col>
                                          <v-col v-else><v-icon color="grey"
                                              @click="setInformation('user','video',true)">mdi-checkbox-blank-outline</v-icon> </v-col>
                                      </v-row>
                                        <v-row>
                                            <v-col>Notifications</v-col>
                                            <v-col v-if="user.notifications"><v-icon color="success"
                                                    @click="setInformation('user','notifications',false)">mdi-checkbox-marked</v-icon> </v-col>
                                            <v-col v-else><v-icon color="grey"
                                                    @click="setInformation('user','notifications',true)">mdi-checkbox-blank-outline</v-icon> </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>Notifications Frequency</v-col>
                                            <v-col>{{ user.notifications_freq }} <v-icon small color="secondary" @click="notificationPrefDialog=true;">mdi-pencil</v-icon></v-col>
                                        </v-row>
                                        
                                        <v-row>
                                            <v-col>Category</v-col>
                                            <v-col>{{ user.category }} <v-icon small color="secondary" @click="userCategoryDialog=true;">mdi-pencil</v-icon>
                                                </v-col>
                                        </v-row>
                                        <v-row v-if="user.category && ['adopter', 'breeder', 'shelter', 'buyer'].includes(user.category)">
                                          <v-col class="text-capitalize">{{ user.category }} Questionnaire Status</v-col>
                                          <v-col>
                                            <span v-if="user.category=='adopter' || user.category=='buyer'">{{ user.adopter_status }} </span>
                                            <span v-if="user.category=='breeder'">{{ user.breeder_status }} </span>
                                            <span v-if="user.category=='shelter'">{{ user.shelter_status }} </span>
                                             <v-icon small color="secondary" @click="questionnaireStatusDialog=true;">mdi-pencil</v-icon>
                                            
                                          </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>

                            </v-col>
                            <v-col cols="12" md="6">
                                <v-card class="ma-4 mild-shadow">
                                    <v-card-text>
                                        <div class="text-overline pb-4 grey--text text--lighten-1">Dates</div>
                                        <v-row dense>
                                            <v-col>Created on</v-col>
                                            <v-col>{{ user.created_at | moment("D MMM YY, h:mm a") }}</v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col>Updated on</v-col>
                                            <v-col>{{ user.updated_at | moment("D MMM YY, h:mm a") }}</v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col>Last Active on</v-col>
                                            <v-col>{{ user.last_active_at | moment("D MMM YY, h:mm a") }}</v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col>Deleted on</v-col>
                                            <v-col v-if = user.deleted_at>{{ user.deleted_at | moment("D MMM YY, h:mm a") }}</v-col>
                                            <v-col v-else></v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col>Delete Reason</v-col>
                                            <v-col>{{ user.delete_reason }}</v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col>Banned on</v-col>
                                            <v-col>{{ user.banned_at | moment("D MMM YY, h:mm a") }}</v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>

                                <v-card class="ma-4 mild-shadow">
                                    <v-card-text>
                                        <div class="text-overline pb-4 grey--text text--lighten-1">Application Info</div>
                                        <v-row dense>
                                            <v-col>App Installed</v-col>
                                            <v-col>{{ user.app_installed ? 'Yes' : 'No' }}</v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col>App Device</v-col>
                                            <v-col>{{ user.app_device ? user.app_device : '-' }}</v-col>
                                        </v-row>

                                        <v-row dense>
                                            <v-col>Subscription Route</v-col>
                                            <v-col>{{ user.subscription_route ? user.subscription_route : '-' }}</v-col>
                                        </v-row>

                                        <v-row dense>
                                            <v-col>Language</v-col>
                                            <v-col>{{ user.language }}</v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col>IP address</v-col>
                                            <v-col>{{ user.ip_address }}</v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <v-col cols="12" md="6">
                                <v-card class="ma-4 mild-shadow">
                                    <v-card-text>
                                        <div class="text-overline pb-4 grey--text text--lighten-1">Follow-ups</div>
                                        <v-row>
                                            <v-col>Pet Addition</v-col>
                                            <v-col>{{ user.pet_add_followup_at | moment("D MMM, h:mm a") }}</v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                            </v-col>

                            
                        </v-row>

                    </v-tab-item>
                    <v-tab-item v-if="user.pets && user.pets.length > 0">
                        <div class="pa-2" v-if="user.pets">
                            <v-card v-for="(pet) in user.pets" :key="pet.xid" class="my-4 mild-shadow">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="3">
                                            <v-img style="border-radius:10px"
                                                :src="pet.avatar ? pet.avatar.photo : require('@/assets/logo/logo-bg2.jpg')"
                                                :lazy-src="require('@/assets/img/lazy-bg.jpg')" cover max-height="300"
                                                :class="pet.active==0?'greyscale white--text align-end':'white--text align-end'"
                                                gradient="to left bottom , rgba(0,0,0,.01) 50%, rgba(0,0,0,.7)"></v-img>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="pb-6">
                                            <div class="text-subtitle-1 font-weight-bold black--text float-left">{{ pet.name }}


                                                <v-chip dark small class="text-caption font-weight-medium my-1 mr-1" v-show="pet.active == 0" color="grey">
                                                    INACTIVE
                                                </v-chip>
                                                <v-chip dark small class="text-caption font-weight-medium my-1 mr-1" v-show="pet.active" color="green">
                                                    ACTIVE
                                                </v-chip>
                                                <v-chip small class="text-caption font-weight-medium my-1" v-show="pet.deleted" color="error">
                                                  DELETED
                                                </v-chip>
                                            </div>
                                          <div class="text-center">


                                            <v-menu bottom left >
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on">
                                                  <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                              </template>

                                              <v-list dense nav>

                                                <v-list-item @click="editPet(pet)">
                                                  <v-list-item-icon class="mr-2">
                                                    <v-icon small>$vuetify.icons.edit-icon</v-icon>
                                                  </v-list-item-icon>
                                                  <v-list-item-content>
                                                    <v-list-item-title>Edit</v-list-item-title>
                                                    <v-list-item-subtitle>Update your pet's details and photos</v-list-item-subtitle>
                                                  </v-list-item-content>
                                                </v-list-item>

                                                <v-list-item @click="petXid=pet.xid;setInformation('pet', 'active', false)" v-if="pet.active == 1">
                                                  <v-list-item-icon class="mr-2">
                                                    <v-icon small>mdi-eye-off-outline</v-icon>
                                                  </v-list-item-icon>
                                                  <v-list-item-content>
                                                    <v-list-item-title>Hide (Unpublish)</v-list-item-title>
                                                    <v-list-item-subtitle>Keep your pet's profile hidden.</v-list-item-subtitle>
                                                  </v-list-item-content>
                                                </v-list-item>

                                                <v-list-item @click="petXid=pet.xid;setInformation('pet', 'active', true)" v-else>
                                                  <v-list-item-icon class="mr-2">
                                                    <v-icon small>mdi-eye-outline</v-icon>
                                                  </v-list-item-icon>
                                                  <v-list-item-content>
                                                    <v-list-item-title>Visible (Publish)</v-list-item-title>
                                                    <v-list-item-subtitle>Keep your pet's profile visible.</v-list-item-subtitle>
                                                  </v-list-item-content>
                                                </v-list-item>

                                                <v-list-item @click="petXid=pet.xid;setInformation('pet', 'delete', true)">
                                                  <v-list-item-icon class="mr-2">
                                                    <v-icon small>mdi-delete-outline</v-icon>
                                                  </v-list-item-icon>
                                                  <v-list-item-content>
                                                    <v-list-item-title>Delete</v-list-item-title>
                                                    <v-list-item-subtitle>Delete your pet's profile.</v-list-item-subtitle>
                                                  </v-list-item-content>
                                                </v-list-item>
                                              </v-list>
                                            </v-menu>
                                          </div>
                                          <div class="clearfix text-caption">{{ pet.breed }}</div>
                                            <div class="text-caption">{{ pet.age ? pet.age.long : '' }}</div>
                                            <div class="text-caption">{{ pet.gender == 'M' ? 'Male' : 'Female' }}, {{ pet.weight }} {{ pet.weight_unit }}</div>
                                            <div class="text-caption">{{ pet.location_description }}</div>

                                            <div class="py-4">

                                            <div class="text-caption"><strong>Reg. In:</strong>{{ pet.reg_in }}</div>

                                            <div class="text-caption"><strong>Reg #:</strong>{{ pet.reg_num }}</div>

                                        </div>

                                            <v-row>
                                                <v-col cols="8" class="text-caption pb-1">Pedigree Certified</v-col>
                                                <v-col class="text-caption pb-1">{{ pet.pedigree_certified ? 'Yes' : 'No' }} </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="8" class="text-caption py-1">DNA Tested</v-col>
                                                <v-col class="text-caption py-1">{{ pet.dna_tested ? 'Yes' : 'No' }} </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="8" class="text-caption py-1">Vaccinated</v-col>
                                                <v-col class="text-caption py-1">{{ pet.vaccinated ? 'Yes' : 'No' }} </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3">
                                            <div class="text-caption secondary--text py-2">Looking for a {{ pet.purpose }}</div>
                                            <div class="text-caption">{{ pet.description }}</div>
                                            <v-divider class="my-4"></v-divider>
                                            <div>
                                            <v-row v-if="pet.purpose == 'buyer'">
                                                <v-col cols="8" class="text-caption pb-1">Price</v-col>
                                                <v-col class="text-caption pb-1">{{ pet.price }} {{ pet.price_ccy }} </v-col>
                                            </v-row>
                                            <v-row v-if="pet.purpose == 'lover' && pet.gender=='M'">
                                                <v-col cols="8" class="text-caption py-1">Stud Fee</v-col>
                                                <v-col class="text-caption py-1">{{ pet.stud_fees }} {{ pet.stud_fees_ccy }} </v-col>
                                            </v-row>
                                            <v-row v-if="pet.purpose == 'adopter'">
                                                <v-col cols="8" class="text-caption py-1">Adoption Fee</v-col>
                                                <v-col class="text-caption py-1">{{ pet.adoption_fees }} {{ pet.adoption_fees_ccy }} </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col cols="8" class="text-caption pb-1">Good with kids</v-col>
                                                <v-col class="text-caption pb-1">{{ pet.good_with_kids ? 'Yes' : 'No' }} </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="8" class="text-caption py-1">Good with cats</v-col>
                                                <v-col class="text-caption py-1">{{ pet.good_with_cats ? 'Yes' : 'No' }} </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="8" class="text-caption py-1">Good with other dogs</v-col>
                                                <v-col class="text-caption py-1">{{ pet.good_with_other_dogs ? 'Yes' : 'No' }} </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="8" class="text-caption py-1">Potty trained</v-col>
                                                <v-col class="text-caption py-1">{{ pet.potty_trained ? 'Yes' : 'No' }} </v-col>
                                            </v-row>
                                        </div>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3">




                                            <v-row>
                                            <v-col cols="8" class="text-caption pb-1">Profile Verified</v-col>
                                            <v-col class="pb-1" v-if="pet.video_verified"><v-icon color="success"
                                                    @click="petXid=pet.xid;setInformation('pet','video',false);">mdi-checkbox-marked</v-icon> </v-col>
                                            <v-col class="pb-1" v-else><v-icon color="grey"
                                                    @click="petXid=pet.xid;setInformation('pet','video',true);">mdi-checkbox-blank-outline</v-icon> </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="8" class="text-caption py-1">Pedigree Verified</v-col>
                                            <v-col class="py-1" v-if="pet.pedigree_verified"><v-icon color="success"
                                                    @click="petXid=pet.xid;setInformation('pet','pedigree',false);">mdi-checkbox-marked</v-icon> </v-col>
                                            <v-col class="py-1" v-else><v-icon color="grey"
                                                    @click="petXid=pet.xid;setInformation('pet','pedigree',true)">mdi-checkbox-blank-outline</v-icon> </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="8" class="text-caption py-1">DNA Verified</v-col>
                                            <v-col class="py-1" v-if="pet.dna_verified"><v-icon color="success"
                                                    @click="petXid=pet.xid;setInformation('pet','dna',false)">mdi-checkbox-marked</v-icon> </v-col>
                                            <v-col class="py-1" v-else><v-icon color="grey"
                                                    @click="petXid=pet.xid;setInformation('pet','dna',true)">mdi-checkbox-blank-outline</v-icon> </v-col>
                                        </v-row>



                                        <v-divider class="my-6"></v-divider>


                                          <v-row>
                                            <v-col cols="7" class="text-caption py-1">Status Checked At</v-col>
                                            <v-col class="text-caption py-1">{{pet.status_checked_at | moment("D MMM YY, h:mm a") }}</v-col>
                                          </v-row>
                                          <v-row>
                                            <v-col cols="8" class="text-caption py-1">Status Check Blocked</v-col>
                                            <v-col class="py-1" v-if="pet.status_check_blocked"><v-icon color="success"
                                                                                                @click="petXid=pet.xid;setInformation('pet','status_check_blocked',false)">mdi-checkbox-marked</v-icon> </v-col>
                                            <v-col class="py-1" v-else><v-icon color="grey"
                                                                               @click="petXid=pet.xid;setInformation('pet','status_check_blocked',true)">mdi-checkbox-blank-outline</v-icon> </v-col>
                                          </v-row>
                                          <v-row>
                                            <v-col cols="8" class="text-caption py-1">Status Check Sequence</v-col>
                                            <v-col class="text-caption py-1">{{ pet.status_check_seq}}</v-col>
                                          </v-row>
                                          <v-row>
                                            <v-col cols="8" class="text-caption py-1">Status Rating</v-col>
                                            <v-col class="text-caption py-1">{{ pet.status_rating}}</v-col>
                                          </v-row>

                                          <v-divider class="my-6"></v-divider>


                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" sm="3" md="2" lg="2" :key="'picture'+pic.xid" v-for="(pic) in pet.photos" align="center">
                                            <user-details-pet-photo :photo="pic" @update-user-detail="updateUserData" @fetch-user-detail="getUser"></user-details-pet-photo>
                                        </v-col>
                                        <v-col cols="6" sm="3" md="2" lg="2" :key="'video'+vid.xid" v-for="(vid) in pet.videos">
                                            <user-details-pet-video :video="vid" @update-user-detail="updateUserData" @fetch-user-detail="getUser"></user-details-pet-video>
                                        </v-col>
                                        <pet-photos-and-videos :pet="pet" @update-user-detail="updateUserData"></pet-photos-and-videos>
                                    </v-row>
                                    <user-details-pet-profile-verification :pet="pet"></user-details-pet-profile-verification>
                                </v-card-text>

                            </v-card>
                        </div>
                        <div v-else class="pa-2 text-center">No pet exists</div>
                    </v-tab-item>
                    <v-tab-item v-if="user.preferences && user.preferences.length > 0">
                        <v-row no-gutters>
                            <v-col cols="12" sm="6" md="6" lg="4" xl="3" v-for="(preference, index) in user.preferences" :key="index" class="pa-2">
                                <v-card >
                                    <v-card-text>
                                    <v-chip small class="ma-1"> {{preference.pet_type.type}} </v-chip>
                                    <v-chip small class="ma-1"> {{preference.age == 'B' ? 'Baby ' : (preference.age == 'Y' ? 'Young ' : (preference.age == 'A' ? 'Adult ' : ( preference.age == 'S' ? 'Senior ' : 'Any age')))}} </v-chip>
                                    <v-chip small class="ma-1"> {{preference.gender == 'M' ? 'Male ' : (preference.gender == 'F' ? 'Female ' : 'Any gender')}} </v-chip>
                                    <v-chip small class="ma-1"> {{preference.pet_size  ? preference.pet_size.size+' size':'Any size'}} </v-chip>
                                    <v-chip small class="ma-1"> {{preference.pet_breed ? preference.pet_breed.breed+' ':'Any breed'}} </v-chip>
                                    <v-chip small class="ma-1"> {{preference.available_for ? 'For '+ preference.available_for: 'For sale/adoption'}} </v-chip>
                                    <v-chip small class="ma-1"> {{'Within ' + preference.pref_distance + ' Miles'}} </v-chip>

                                    <div class="pa-4 pb-0">
                                        <p>
                                            {{ preference.description }}
                                        </p>
                                    </div>
                                </v-card-text>

                                <v-card-actions>
                                    <v-chip class="success" v-if="preference.active">Active</v-chip>
                                    <v-chip class="error" v-else>Inactive</v-chip>
                                    <v-spacer></v-spacer>
                                    <v-btn small class=" error" @click="preferenceXid=preference.xid;preferenceDialog=true;"><v-icon left small>mdi-delete</v-icon> Delete</v-btn>
                                    </v-card-actions>

                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <user-subscriptions :user="user"></user-subscriptions>
                    </v-tab-item>
                    <v-tab-item>
                        <user-conversations :user="user"></user-conversations>
                    </v-tab-item>
                    <v-tab-item>
                        <div class="pa-8">All notifications we sent to user</div>
                    </v-tab-item>
                    <v-tab-item>
                      <user-scam-reports :user="user"></user-scam-reports>
                    </v-tab-item>
                    <v-tab-item>
                        <user-questionnaire :user="user"></user-questionnaire>
                    </v-tab-item>

                </v-tabs-items>
            </div>
        </div>

        <edit-pet v-if="petXid" :xid="petXid" v-model="editPetModal" @fetch-user-detail="getUser"></edit-pet>

        <v-dialog v-model="confirmDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title >Confirm</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="confirmDialog=false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <p class="text-subtitle-2" v-if="updateFor=='pet' && updateField=='delete'">
                        Are you sure you want to delete pet?
                    </p>
                    <p class="text-subtitle-2" v-else-if="updateFor=='pet' && updateField=='active' && updateValue == false">
                      Are you sure you want to hide the pet?
                    </p>
                    <p class="text-subtitle-2" v-else-if="updateFor=='pet' && updateField=='active' && updateValue == true">
                      Are you sure you want to show the pet?
                    </p>
                    <p class="text-subtitle-2" v-else-if="updateFor=='pet' && updateField=='status_check_blocked' && updateValue == true">
                      Are you sure you want to block status check for the pet?
                      </p>
                    <p class="text-subtitle-2" v-else-if="updateFor=='pet' && updateField=='status_check_blocked' && updateValue == false">
                      Are you sure you want to unblock status check for the pet?
                    </p>
                    <p class="text-subtitle-2" v-else-if="updateFor=='user' && updateField=='user_category'">
                      Are you sure you want to change user category?
                    </p>
                    <p class="text-subtitle-2" v-else>
                        Are you sure you want to {{updateValue ? 'verify' : 'unverify'}} {{updateFor}} {{updateField}}?
                    </p>
                        <v-checkbox v-model="send_notification" value=true v-if="updateField!='delete' && updateField!='active' && updateField!='status_check_blocked'">
                            <template v-slot:label>
                                <span class="text-caption">Send notification email</span>
                            </template>
                            </v-checkbox>
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="grey" @click="confirmDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="updating" :disabled="updating" @click="updateInformation">Yes</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog v-model="nameDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-2">User's Name</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="nameDialog=false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field v-model="userUpdatedName" outlined label="Name"></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="grey" @click="nameDialog = false">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="updating" :disabled="updating" @click="setInformation('user', 'name', userUpdatedName)">Submit</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog v-model="questionnaireStatusDialog" max-width="400">
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title class="text-subtitle-2">Questionnaire Status</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="questionnaireStatusDialog=false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-select style="font-size:14px !important" :items="['submitted', 'pending', 'verified']"  v-model="questionnaire_status" mandatory outlined dense hide-details></v-select>
            </v-card-text>

            <v-card-actions>
              <v-btn text color="grey" @click="questionnaireStatusDialog = false">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" :loading="updating" :disabled="updating" @click="setInformation('user','questionnaire_status', questionnaire_status)">Submit</v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>

        <v-dialog v-model="userCategoryDialog" max-width="400">
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title class="text-subtitle-2">User Category</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="userCategoryDialog=false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-select style="font-size:14px !important" :items="['adopter', 'buyer', 'mate-seeker', 'breeder', 'shelter', 'individual']"  v-model="user_category" mandatory outlined  dense hide-details></v-select>
            </v-card-text>

            <v-card-actions>
              <v-btn text color="grey" @click="userCategoryDialog = false">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" :loading="updating" :disabled="updating" @click="setInformation('user','user_category', user_category)">Submit</v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>

        <v-dialog v-model="notificationPrefDialog" max-width="400">
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title class="text-subtitle-2">Notification Preference</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="notificationPrefDialog=false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-select style="font-size:14px !important" :items="items" item-text="label" item-value="value" v-model="notifications_freq" mandatory outlined dense hide-details></v-select>
            </v-card-text>

            <v-card-actions>
              <v-btn text color="grey" @click="notificationPrefDialog = false">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" :loading="updating" :disabled="updating" @click="setInformation('user','notification_frequency',notifications_freq)">Submit</v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>
        <v-dialog v-model="preferenceDialog" max-width="400">
            <v-card>
              <v-toolbar flat>
                  <v-toolbar-title >Confirm</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="preferenceDialog=false">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-toolbar>

              <v-card-text>
                  <p class="text-subtitle-2">
                    Are you sure you want to delete preference?
                  </p>
              </v-card-text>

                <v-card-actions>
                    <v-btn text color="grey" @click="preferenceDialog = false">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="updating" :disabled="updating" @click="deletePreference()">Submit</v-btn>
                </v-card-actions>

</v-card>
</v-dialog>

        <v-dialog v-model="sendMessageDialog" max-width="600">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title>Send Direct Message</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="sendMessageDialog=false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <strong>Email: </strong>{{user.email}}
                </v-card-text>
                <v-card-text>
                    <v-text-field dense outlined v-model="messageSubject" label="Subject"></v-text-field>
                </v-card-text>
                <v-card-text>
                    <v-textarea dense outlined v-model="messageBody" label="Message Body"></v-textarea>
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="grey" @click="sendMessageDialog = false">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="sendMessageloading" :disabled="sendMessageloading" @click="sendMessage">Send Message</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>


        <v-dialog v-model="confirmPremiumOffer" max-width="600">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title>Confirm</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="confirmPremiumOffer=false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    Are you sure you want to send a 7-Day Premium offer to this user?
                </v-card-text>
                

                <v-card-actions>
                    <v-btn text color="grey" @click="confirmPremiumOffer = false">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="sendingPremiumOffer" :disabled="sendingPremiumOffer" @click="sendPremiumOffer">Send Offer</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" bottom>
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>


    </v-container>
</template>

<script>

import UserDetailsPetCard from './components/UserDetailsPetCard.vue';
import UserDetailsPetPhoto from './components/UserDetailsPetPhoto.vue';
import UserDetailsPetVideo from './components/UserDetailsPetVideo.vue';
import UserDetailsPetProfileVerification from './components/UserDetailsPetProfileVerification.vue';
import EditPet from "./components/EditPet.vue";
import PetPhotosAndVideos from "./PetPhotosAndVideos.vue";
import UserSubscriptions from './components/UserSubscriptions.vue';
import UserConversations from "./components/UserConversations.vue";
import UserScamReports from "./components/UserScamReports.vue";
import UserQuestionnaire from "./components/UserQuestionnaire.vue";

export default {
    name: 'UserDetails',
    props: ['xid'],
    components: {
        PetPhotosAndVideos,
        EditPet,
        UserDetailsPetCard,
        UserDetailsPetPhoto,
        UserDetailsPetVideo,
        UserDetailsPetProfileVerification,
        UserSubscriptions,
        UserConversations,
        UserScamReports,
        UserQuestionnaire,
    },
    mounted() {
        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login').catch(err => { });
        } else {
            this.getUserProfile();
        }

        this.notifications_freq= this.user.notifications_freq;
    },
    data() {
        return {
            sendMessageDialog: false,
            sendMessageloading: false,
            messageSubject: '',
            messageBody: '',

            items: [{
              label: 'Daily',
              value: 'daily'
            },
              {
                label: 'Weekly',
                value: 'weekly'
              },
              {
                label: 'Off',
                value: 'off'
              }
            ],
            user_category: '',

            show_freq_dropdown: false,
            notifications_freq: '',
            petXid: '',
            send_notification: false,
            editPetModal: false,
            preferenceDialog: false,
            preferenceXid: '',

            user: {},
            loading: false,
            snackbar: false,
            snackbar_text: '',
            tab: null,
            dialog: false,
            notifications: false,
            sound: true,
            widgets: false,
            confirmDialog: false,
            nameDialog: false,
            updating: false,

            questionnaireStatusDialog: false,
            userCategoryDialog: false,
            notificationPrefDialog: false,
            questionnaire_status: '',

            userUpdatedName: '',
            updateFor: "user",
            updateField: null,
            updateValue: false,

            confirmPremiumOffer: false,
            sendingPremiumOffer: false,
        }
    },
    methods: {
        updateUserData(user) {
            //console.log(user);
            this.user = user;
        },
        editPet(pet) {
            this.petXid = pet.xid;
            this.editPetModal = true;
        },
        getUser() {
          let xid = this.xid;
          this.$store.dispatch('getUser', xid).then((res) => {
              this.user = res;
              this.notifications_freq = this.user.notifications_freq;
          }).catch(err => {
          });
        },
        getUserProfile() {
            this.loading = true;
            let xid = this.xid;
            this.$store.dispatch('getUser', xid).then((res) => {
                this.loading = false;
                this.user = res;
                this.userUpdatedName = this.user.name;
                this.notifications_freq = this.user.notifications_freq;
                this.user_category = this.user.category;

                if (this.user_category == 'adopter') {
                  this.questionnaire_status = this.user.adopter_status;
                } else if (this.user_category == 'breeder') {
                  this.questionnaire_status = this.user.breeder_status;
                } else if (this.user_category == 'shelter') {
                  this.questionnaire_status = this.user.shelter_status;
                }
            })
                .catch(err => {
                    this.loading = false;
                    this.snackbar_text = "Oops, Couldn't get data. Please try again.";
                    this.snackbar = true;
                });
        },
        updateUser(payload) {
            this.updating = true;
            this.$store.dispatch('updateUser', payload).then((res) => {
                this.confirmDialog = false;
                this.nameDialog = false;
                this.userCategoryDialog = false;
                this.notificationPrefDialog = false;
                this.questionnaireStatusDialog = false;
                this.user = res;
                this.updating = false;
                this.snackbar_text = "Account updated";
                this.snackbar = true;
                this.clearInformation();
            })
                .catch(err => {
                    this.updating = false;
                    this.confirmDialog = false;
                    this.userCategoryDialog = false;
                    this.questionnaireStatusDialog = false;
                    this.nameDialog = false;
                    this.snackbar_text = "Oops, Error updating account. Please try again.";
                    this.snackbar = true;
                    this.clearInformation();
                });
        },
        updatePet(payload) {
            this.updating = true;
            this.$store.dispatch('updatePet', payload).then((res) => {
                this.confirmDialog = false;
                this.user = res;
                this.updating = false;
                this.snackbar_text = "Pet updated";
                this.snackbar = true;
                this.clearInformation();
            })
                .catch(err => {
                    this.updating = false;
                    this.confirmDialog = false;
                    this.snackbar_text = "Oops, Error updating pet. Please try again.";
                    this.snackbar = true;
                    this.clearInformation();
                });
        },

        deletePreference() {
            this.$store.dispatch('deletePreferences', this.preferenceXid).then((res) => {
                this.getUser();
                this.preferenceDialog = false;
                this.snackbar_text = "Preference Delete.";
                this.snackbar = true;
                this.preferenceXid = null;
            }).catch(err => {
                this.preferenceDialog = false;
                this.snackbar_text = "Oops, Error updating pet. Please try again.";
                this.snackbar = true;
            });
        },
        setInformation(updateFor,updateField,updateValue)
        {
            this.updateFor = updateFor
            this.updateField = updateField
            this.updateValue = updateValue
            this.send_notification = false

            if (this.updateField != 'notification_frequency' && this.updateField != 'user_category'
                && this.updateField != 'questionnaire_status' && this.updateField != 'name') {
                this.confirmDialog = true
            } else {
                this.updateInformation();
            }
        },

        clearInformation()
        {
            this.updateFor='';
            this.updateField='';
            this.updateValue='';
            this.send_notification = false;
        },

        updateInformation() {
            if (this.updateFor === 'user') {
                let payload = { xid: this.user.xid };
                payload.send_notification = this.send_notification == "true" ? true : false

                if (this.updateField === 'email') {
                    payload.email_verified = this.updateValue;
                    this.updateUser(payload)
                } else if (this.updateField === 'phone') {
                    payload.phone_verified = this.updateValue;
                    this.updateUser(payload)
                } else if (this.updateField === 'video') {
                    payload.video_verified = this.updateValue;
                    this.updateUser(payload)
                } else if (this.updateField === 'notifications') {
                    payload.notifications = this.updateValue;
                    this.updateUser(payload)
                } else if (this.updateField === 'notification_frequency') {
                    payload.notification_frequency = this.updateValue;
                    this.updateUser(payload)
                } else if (this.updateField === 'user_category') {
                    payload.user_category = this.updateValue;
                    this.updateUser(payload)
                } else if (this.updateField === 'questionnaire_status') {
                    payload.questionnaire_status = this.questionnaire_status;
                    this.updateUser(payload)
                } else if (this.updateField === 'name') {
                    payload.name = this.updateValue;
                    this.updateUser(payload)
                }
            } else if (this.updateFor === 'pet') {
                let payload = { xid: this.petXid };
                payload.send_notification = this.send_notification == "true" ? true : false
                if (this.updateField === 'pedigree') {
                    payload.pedigree_verified = this.updateValue;
                    this.updatePet(payload)
                } else if (this.updateField === 'video') {
                    payload.video_verified = this.updateValue;
                    this.updatePet(payload)
                } else if (this.updateField === 'dna') {
                    payload.dna_verified = this.updateValue;
                    this.updatePet(payload)
                } else if (this.updateField === 'status_check_blocked') {
                    payload.status_check_blocked = this.updateValue;
                    this.updatePet(payload)
                } else if (this.updateField === 'active') {
                    payload.active = this.updateValue;
                    this.updatePet(payload)
                } else if (this.updateField === 'delete') {
                    payload.delete = this.updateValue;
                    this.updatePet(payload)
                }
            }
        },
        sendMessage() {
          this.sendMessageloading = true;
          let xid = this.xid;
          let payload = {xid: xid, subject: this.messageSubject, body: this.messageBody};
          this.$store.dispatch('sendDirectMessage', payload).then((res) => {
            this.sendMessageloading = false;
            this.sendMessageDialog = false;
            this.messageSubject = '';
            this.messageBody = '';
            this.snackbar_text = "Message sent to user.";
            this.snackbar = true;
          })
              .catch(err => {
                this.sendMessageloading = false;
                this.snackbar_text = "Oops, Couldn't send message. Please try again.";
                this.snackbar = true;
              });
        },

        sendPremiumOffer() {
          this.sendingPremiumOffer = true;
          let xid = this.xid;
          this.$store.dispatch('sendOneWeekPremiumOffer', {xid}).then((res) => {
            this.sendingPremiumOffer = false;
            this.confirmPremiumOffer = false;
            this.snackbar_text = "Offer sent to user.";
            this.snackbar = true;
          })
              .catch(err => {
                this.sendingPremiumOffer = false;
                this.snackbar_text = "Oops, Couldn't send the offer. Please try again.";
                this.snackbar = true;
              });
        },
    }
}
</script>

<style>.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
    transition: transform .2s ease-in-out;
}
.greyscale {
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}</style>