<template>
<v-container fluid class="home">

    <div v-if="loading" class="pt-12">

        <v-row no-gutters>
            <v-col cols="12" justify="center" align="center" class="pt-12">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>

        </v-row>

    </div>
    <div v-else class="mb-16">

       <div class="my-2 mb-4">
            <v-row no-gutters>
                <v-col cols="12" md="6">
                  <h4 class="text-h6 mb-4" >AI Text Audit Log</h4>
                  </v-col>
                  <v-col cols="12" md="6">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search By Email"
                      single-line
                      outlined
                      clearable
                      @click:clear="onClearSearch"
                      @keyup.enter="getAiTextChangeAudit"
                      class="mb-4"
                  ></v-text-field>
                 
                </v-col>

            </v-row>

        </div>
        <v-row  v-if="errorMessage">
            <v-col cols="12" class="pa-12 text-center">
                <div>{{ errorMessage }}</div>
            </v-col>
        </v-row>
        <v-row>
          <v-data-table
              v-if="!errorMessage"
              :headers="headers"
              :items="combinedItems"
              :items-per-page="options.itemsPerPage"
              :page.sync="options.page"
              :hide-default-footer="true"
              :loading="loading"
              class="elevation-1 col-12"
          >
            <template v-slot:item.fullName="{ item }">
              <span v-html="item.fullName"></span>
            </template>
          </v-data-table>
        </v-row>

    </div>

    <div class="text-center col-md-12">
      <v-pagination
          v-model="options.page"
          :length="totalPages"
          :total-visible="7"
          @input="getAiTextChangeAudit()"
      ></v-pagination>
    </div>

    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

</v-container>
</template>

<script>
//import ConvertVideoCard from "@/views/components/AiTextAuditCard.vue";

export default {
    name: 'AiTextAudit',
    mounted() {

        // this.totalPages = Math.ceil(this.$store.getters.pending.aiTextChangeAudit / this.options.itemsPerPage);
        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login').catch(err => {});
        } else {
            this.getAiTextChangeAudit();
        }
    },
    computed: {
      combinedItems() {
        return this.$store.getters.aiTextChangeAudit.map(item => ({
          ...item,
          fullName: `${item.name}<br> ${item.email}`,
        }));
      },
      totalPages() {
        return Math.ceil(this.$store.getters.pending.aiTextChangeAudit / this.options.itemsPerPage);
      },

    },
    data() {
        return {
            options: {
              page: 1,
              itemsPerPage: 50,
            },
            loading: false,
            snackbar: false,
            snackbar_text: '',
            search: '',
            errorMessage: '',

            headers: [
              {
                text: 'User Name/Email',
                align: 'start',
                sortable: false,
                value: 'fullName',
                cellClass: 'text-caption'
              },
              {
                text: 'Original Text',
                align: 'start',
                sortable: false,
                value: 'original_text',
                cellClass: 'text-caption'
              },
              {
                text: 'Processed Text',
                align: 'start',
                sortable: false,
                value: 'processed_text',
                cellClass: 'text-caption'
              },
              {
                text: 'Context',
                align: 'start',
                sortable: false,
                value: 'context',
                cellClass: 'text-caption'
              },
              {
                text: 'Field Name',
                align: 'start',
                sortable: false,
                value: 'field',
                cellClass: 'text-caption'
              },
              {
                text: 'Pet',
                align: 'start',
                sortable: false,
                value: 'pet_name',
                cellClass: 'text-caption'
              }
            ],
        }
    },
    components: {
      //ConvertVideoCard,
    },
    methods: {
      onClearSearch() {
            this.search = ''; 
            this.getAiTextChangeAudit();
        },

      getAiTextChangeAudit() {
          this.loading = true;
          let email = this.search;
          const { page } = this.options;

          this.$store.dispatch('getAiTextChangeAudit', { page, email }).then((resp) => {
              this.loading = false;
              this.options.page = page;
              this.errorMessage = this.$store.getters.aiTextChangeAudit.length === 0 ? 'No record found' : '';
          })
          .catch(err => {
            this.loading = false;
            this.snackbar_text = "Oops, Couldn't get data. Please try again."+err.text;
            this.snackbar = true;
          });
      },
    }
}
</script>
