<template>
    <div>
        <v-card class="mild-shadow rounded-lg">

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="text--primary text-caption"><b>User:</b>
                        {{ video.email }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-list-item-action-text class="secondary--text">{{ $moment(video.created_at).fromNow(true) }}</v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>

            <v-card height="300">
                <video-player :options="videoOptions" :source="video.cloud_url" :id="video.xid" />
            </v-card>

            <v-card-text><div class="text-caption"><a class="info--text" :href="video.cloud_url" target="_blank"><v-icon small color="blue">mdi-open-in-new</v-icon> Open File</a></div></v-card-text>
            <v-card-text>
                <v-alert v-if="video.explanation" text dense class="text-caption mb-0" :color="video.ai_validated === 0 ? 'error' : 'success'">
                    <p><strong>Analysis: </strong>{{ video.ai_validated ? 'Valid' : 'Invalid'}}</p>
                    <strong>Explanation: </strong>{{ video.explanation }}
                </v-alert>
            </v-card-text>
            <v-card-actions>
                
                <v-btn icon text color="primary" @click="showPetDetails()">
                    <v-icon>mdi-information-outline</v-icon>
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small text color="error" outlined @click="rejectDialog = true" :disabled="disabled">
                    REJECT
                </v-btn>

                <v-btn small color="success" depressed v-if="video.verified" @click="approve(0)" :loading="approving"
                    :disabled="approving || disabled" class="video_approve_btn">
                    APPROVED
                </v-btn>
                <v-btn small text color="success" outlined v-else @click="approve(1)" :loading="approving"
                    :disabled="approving || disabled" class="video_approve_btn">
                    APPROVE
                </v-btn>

            </v-card-actions>

        </v-card>

        
        <pet-profile-info v-if="isMounted" v-model="petProfileDialog" :pet="video.pet" />
   


        <v-dialog v-model="rejectDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1 font-weight-bold">Reject Reason</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="rejectDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="rejectForm" v-model="valid" lazy-validation @submit.prevent="reject">
                    <v-card-text class="pt-4">
                        <div class="pb-4">Provide a reason for rejection which will be communicated with the user.</div>

                        <v-textarea dense outlined auto-grow row-height="20" rows="3" placeholder="Reject Reason" v-model="rejectReason" :rules="rejectReasonRules"></v-textarea>

                        <v-chip small class="ma-1" @click="rejectReason='Date is not shown'">Date is not shown</v-chip>
                        <v-chip small class="ma-1" @click="rejectReason='Invalid Format'">Invalid Format</v-chip>
                        <v-chip small class="ma-1" @click="rejectReason='Visuals are not clear'">Visuals are not clear</v-chip>
                        <v-chip small class="ma-1" @click="rejectReason='Irrelevant video'">Irrelevant video</v-chip>
                        <v-chip small class="ma-1" @click="rejectReason='The date must be displayed on a piece of paper and should not be edited into or embedded in the video.'">Date must be on a paper</v-chip>
                        
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="rejecting"
                            :disabled="!valid || rejecting">Reject</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import VideoPlayer from './VideoPlayer.vue'
import PetProfileInfo from "./PetProfileInfo.vue";

export default {
    name: 'VideoCard',
    props: ['video'],

    mounted() {
        this.isMounted = true;
    },
    data() {
        return {
            approving: false,
            petProfileDialog: false,
            rejecting: false,
            rejectDialog: false,
            rejectReason: '',
            tags: ['Cute', 'Funny'],
            selectedTags: [],
            selectedTagsStr: '',
            valid: true,
            disabled: false,
            videoOptions: {
                autoplay: false,
                controls: true,
                playbackRates: [0.25, 0.5, 1, 1.5, 2, 4]
            },
            isMounted: false,
            rejectReasonRules: [
                v => !!v || 'Reject reason is required',
            ],
        }
    },

    components: {
        VideoPlayer,
        PetProfileInfo
    },
    methods: {

        approve(val) {
            let verification_id = this.video.xid;
            let approve = val

            this.approving = true;
            this.$store.dispatch('saveProfileVideo', {
                    approve,
                    verification_id,
                }).then(() => {
                    this.approving = false;
                    this.video.verified = val
                })
                .catch(err => {
                    this.approving = false;
                });
        },
        reject() {
            if (this.$refs.rejectForm.validate()) {
                this.rejecting = true;
                let verification_id = this.video.xid;
                let reject_reason = this.rejectReason
                this.$store.dispatch('rejectProfileVideo', {
                    verification_id,
                    reject_reason
                }).then(() => {
                    this.rejecting = false;
                    this.rejectDialog = false;
                    this.disabled = true;
                })
                    .catch(err => {
                        this.rejecting = false;
                    });
            }  
        },
        showPetDetails() {
            this.petProfileDialog = true;
        },
    }

}
</script>
