<template>

  <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-transition">
        <v-card class="soft-shadow">
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Business Preview</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-col cols="12 pl-6"><div class="text-subtitle-2 font-weight-semi py-6">Detailed Profile</div></v-col>

          <v-card-text>

          <v-row>

            <v-col cols="6" sm="3" md="2" lg="2" :key="'picture'+photo.xid" v-for="(photo) in images" align="center">
              <v-img :src="photo.media_url" cover aspect-ratio="1.333" style="border-radius:10px; min-height:100px"></v-img>
            </v-col>

            <v-col cols="6" sm="3" md="2" lg="2" :key="'picture'+item.xid" v-for="(item) in videos" align="center">
              <video class="v-player" controls :src="item.media_url" style="width: 100%; height: 100%; object-fit: contain; background-color: #000;" autoplay muted></video>
            </v-col>

          </v-row>

        </v-card-text>

          <v-card-text>

            <v-row>
              <v-col cols="12">

                <v-list>
                  <v-list-item class="pl-0">
                    <v-list-item-avatar size="54" v-if="business.logo">
                      <v-img :src="business.logo"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="text-h6 font-weight-semi black--text">{{
                          business.business_name }} </v-list-item-title>
                      <v-list-item-subtitle class="text-caption blue--text">{{ business.tagline
                        }}</v-list-item-subtitle>
                      <v-list-item-subtitle class="text-caption mt-1">{{ business.city }}, {{
                          business.state }}</v-list-item-subtitle>
                    </v-list-item-content>

                  </v-list-item>

                </v-list>

              </v-col>

            </v-row>

            <v-row>
              <v-col cols="12">
                <div class="mb-4 text-caption text-pre-wrap">
                  {{ business.description }}
                </div>
              </v-col>
            </v-row>

            <v-row>

              <v-col cols="12" md="8">
                <div class="text-overline font-weight-bold pb-4 grey--text">Services</div>
                <div v-for="(services, category) in groupedServices" :key="category" class="mb-4">
                  <div class="text-subtitle-2 font-weight-semi mb-2">{{ category }}</div>
                  <div>
                    <v-chip v-for="(service, idx) in services" :key="idx" small outlined
                            color="primary" class="ma-1">
                      {{ service.service_name }}
                    </v-chip>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row v-if="business.professionals.length > 0">
              <div class="text-overline font-weight-bold pa-4 pb-0 grey--text" v-if="business.professionals.length > 1">Our Team
              </div>
              <v-col cols="12" v-for="(item) in business.professionals" :key="item.xid">
                <v-card outlined class="rounded-xl">
                  <v-card-text>

                    <v-row>
                      <v-col cols="12" md="3"  class="d-flex flex-column align-center">
                        <div style="width: 120px;  border-radius: 50%; overflow: hidden;" class="d-flex justify-center">
                          <v-img v-if="item.profile_pic" :src="item.profile_pic" cover
                                 max-width="120" aspect-ratio="1">
                          </v-img>
                          <v-img v-else :src="require('@/assets/logo/logo-bg2.jpg')" cover
                                 max-width="120" aspect-ratio="1">
                          </v-img>
                        </div>
                      </v-col>
                      <v-col cols="12" md="9" >
                        <div class="text-body-1 font-weight-semi black--text"
                             :class="$vuetify.breakpoint.xs ? 'text-center' : ''">{{ item.name }}
                        </div>
                        <div class="text-caption pb-3"
                             :class="$vuetify.breakpoint.xs ? 'text-center' : ''">{{
                            item.designation }}</div>
                        <div class="text-caption" v-show="item.years_of_experience"><span
                            class="black--text font-weight-medium">Years
                                                        of
                                                        Exp.:</span> {{ item.years_of_experience }}</div>
                        <div class="text-caption" v-show="item.qualifications"><span
                            class="black--text font-weight-medium">Qualifications:</span> {{
                            item.qualifications }}</div>
                        <div class="text-caption pt-3 text-pre-wrap">{{ item.description }}</div>
                      </v-col>
                    </v-row>
                  </v-card-text>

                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="8">
                <div class="text-overline font-weight-bold pa-4 mt-4 grey--text">Business Hours</div>
                <div class="mb-8">
                  <v-simple-table dense class="no-borders-table">
                    <tbody>
                    <tr v-for="(data, day) in operatingHours" :key="day">
                      <td class="text-caption font-weight-medium">{{ day }}</td>
                      <td class="text-caption">
                        <span v-if="data.isClosed" class="text-error">Closed</span>
                        <span v-else>{{ formatTime(data.open) }} - {{ formatTime(data.close) }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
  </v-dialog>

</template>

<script>

export default {
  name: 'BusinessPreview',
  props: ['value', 'business'],

  mounted() {
      if(this.business.operating_hours) {
        this.operatingHours = JSON.parse(this.business.operating_hours);
      }
  },

  components: {

  },

  data() {
    return {
      operatingHours: {},

    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    limitedServices() {
      // Limit the services to 4 or 5
      return this.business.services.slice(0, 3);
    },
    remainingServices() {
      // Calculate the remaining services count
      return this.business.services.length - this.limitedServices.length;
    },
    carouselHeight() {
      // Define the desired aspect ratio
      const aspectRatio = 16 / 9;

      // Get the screen width
      const screenWidth = window.innerWidth;

      // Calculate the column width
      let columnWidth;

      if (screenWidth >= 960) {
        // On desktop, col-8 occupies two-thirds of the container
        columnWidth = (screenWidth * 8) / 12;
      } else {
        // On mobile, col-12 occupies the full width
        columnWidth = screenWidth;
      }

      // Calculate the height proportionally to the column width
      return Math.round(columnWidth / aspectRatio) + "px";
    },

    groupedServices() {
      // Group services by category
      return this.business.services.reduce((acc, service) => {
        const category = service.category_name || "Uncategorized";
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(service);
        return acc;
      }, {});
    },

    mediaByType() {
      // Filter the media array into images and videos
      const images = this.business.media.filter(item => item.media_type === 'image');
      const videos = this.business.media.filter(item => item.media_type === 'video');

      return { images, videos };
    },
    images() {
      return this.mediaByType.images;
    },
    videos() {
      return this.mediaByType.videos;
    },


  },

  methods: {

    formatTime(time) {
      // Return "Closed" if the time is empty
      if (!time) return "Closed";

      // Format the time (e.g., 10:00 to 10:00 AM/PM)
      const [hour, minute] = time.split(":");
      const formattedHour = (hour % 12) || 12; // Convert to 12-hour format
      const amPm = hour >= 12 ? "PM" : "AM";
      return `${formattedHour}:${minute} ${amPm}`;
    },


  }
}
</script>
<style>
.carousel-container {
  width: 100%;
}

.v-carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.no-borders-table {
  width: auto;
  margin: 0 auto;
  border-collapse: collapse;
}

.no-borders-table td {
  padding: 8px 16px;
  border: none !important;
}
</style>