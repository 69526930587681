<template>
<div>
    <v-card class="mild-shadow rounded-lg">

        <v-list-item>
            <v-list-item-content>
                <v-list-item-subtitle class="text--primary text-caption"><b>User:</b> {{video.email}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-list-item-action-text class="secondary--text">{{ $moment(video.created_at).fromNow(true) }}</v-list-item-action-text>
            </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-card height="300">
          <!-- <v-img :src="video.thumb_url" height="300" cover style="border-radius:5px" class="d-flex align-center justify-center" aspect-ratio="1" v-if="video.thumb_url">
            <v-btn icon dark @click="openVideoPlayer(video.video_url)" style="background-color:rgba(0,0,0,0.30); position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
              <v-icon>mdi-play</v-icon>
            </v-btn>
          </v-img> -->
          <video class="v-player" v-if="video.video_url" controls :src="video.video_url" style="width: 100%; height: 100%; object-fit: contain; background-color: #000;"></video>
          <video-player :options="videoOptions" :source="getVideoUrl(video)" :id="video.xid" v-else/>
        </v-card>
        
        <v-card-text>
            <!-- <div class="text-caption">{{ video.video_name }}</div> -->
            <v-chip-group
                multiple
                active-class="primary--text"
                v-model="selectedTags"
                @change="updateTags"
            >
              <v-chip
                  v-for="tag in tags"
                  :key="tag"
                  outlined
                  small
                  :value="tag"
                  :disabled="disabled"
                  
              >
                {{ tag }}
              </v-chip>
            </v-chip-group>
        </v-card-text>

      <v-card-text>
        <v-alert v-if="video.explanation" text dense class="text-caption mb-0" :color="video.ai_validated === 0 ? 'error' : 'success'">
          <p><strong>Analysis: </strong>{{ video.ai_validated ? 'Valid' : 'Invalid'}}</p>
          <strong>Explanation: </strong>{{ video.explanation }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
            <v-btn icon text color="primary" @click="showPetDetails()">
                <v-icon>mdi-information-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn small text color="error" outlined @click="rejectDialog=true" :disabled="disabled">
                REJECT
            </v-btn>

            <v-btn small color="success" depressed v-if="video.verified" @click="approve(0)" :loading="approving" :disabled="approving || disabled" class="video_approve_btn">
                APPROVED
            </v-btn>
            <v-btn small text color="success" outlined v-else  @click="approve(1)" :loading="approving" :disabled="approving || disabled" class="video_approve_btn">
                APPROVE
            </v-btn>

        </v-card-actions>

    </v-card>
    <pet-profile-info v-if="isMounted" v-model="petProfileDialog" :pet="video.pet" />

    <v-dialog v-model="openVideoDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card style="height: 100vh;">
        <v-card-actions class="d-flex justify-end">
          <v-btn icon @click="openVideoDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="d-flex justify-center align-center" style="height: calc(100vh - 56px);">
          <video controls :src="this.video_url" style="width: 100%; height: 100%; object-fit: cover;"></video>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="rejectDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Why rejecting?</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="rejectDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-form ref="rejectForm" v-model="valid" lazy-validation @submit.prevent="reject">
                <v-card-text class="pt-4">
                    <v-radio-group v-model="rejectReason" class="mt-2" mandatory>
                        <v-radio label="Text on video" value="Text on video"></v-radio>
                        <v-radio label="Poor quality" value="Poor quality"></v-radio>
                        <v-radio label="Irrelevant video" value="Irrelevant video"></v-radio>
                    </v-radio-group>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit" :loading="rejecting" :disabled="!valid || rejecting">Reject</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

</div>
</template>

<script>
import VideoPlayer from './VideoPlayer.vue'
import PetProfileInfo from "./PetProfileInfo.vue";

export default {
    name: 'VideoCard',
    props: ['video'],
    data() {
        return {
            petProfileDialog: false,
            approving: false,

            rejecting: false,
            rejectDialog: false,
            rejectReason: '',
            tags: ['Cute', 'Funny'],
            selectedTags: [],
            selectedTagsStr: '',
            isMounted: false,

            openVideoDialog: false,
            video_url: '',

            valid: true,
            disabled: false,
            videoOptions: {
                autoplay: false,
                controls: true,
                playbackRates: [0.25, 0.5, 1, 1.5, 2, 4]
            }
        }
    },

    components: {
      PetProfileInfo,
      VideoPlayer
    },
    mounted() {
        this.isMounted = true;
        this.selectedTags = this.video.tags ? this.video.tags.split(',') : [];
    },
    /* watch: {
      selectedTags : function(selected) {
        if (selected) {
            this.selectedTagsStr = selected.join()
        }
      }
    }, */
    methods: {
        openVideoPlayer(video) {
          this.openVideoDialog = true;
          this.video_url = video;
        },
        getVideoUrl(item) {
          let url='https://api.dyntube.com/v1/live/videos/'+item.video_key+'.m3u8';
          return url;
        },

        approve(val) {
            let video_id = this.video.xid;
            let approve = val

            this.approving = true;
            this.$store.dispatch('saveVideo', {
                    approve,
                    video_id,
                }).then(() => {
                    this.approving = false;
                    this.video.verified = val
                })
                .catch(err => {
                    this.approving = false;
                });
        },
        updateTags() {
            let video_id = this.video.xid;
            let tags = this.selectedTags.join();
            this.approving = true;
            this.$store.dispatch('updateVideo', {
                video_id,
                tags,
            }).then(() => {
                this.approving = false;
            })
            .catch(err => {
                this.approving = false;
            });
        },


        reject() {
            let video_id = this.video.xid;
            let reject_reason = this.rejectReason

            this.$store.dispatch('rejectVideo', {
                video_id,
                reject_reason
            }).then(() => {
                this.rejecting = false;
                this.rejectDialog = false;
                this.disabled=true;
            })
            .catch(err => {
                this.rejecting = false;
            });
        },
        showPetDetails() {
            this.petProfileDialog = true;
        },
    }

}
</script>
