<template>
    <div>
      <v-col cols="12">


        <v-card class="soft-shadow"  @click.stop="openBusinessPreview">
          <v-img v-if="business.cover_media_url" :src="business.cover_media_url" cover min-height="200"
                 max-height="200" class="rounded-t-lg"></v-img>
          <v-card-text class="pt-0">
            <v-list>
              <v-list-item class="pl-0">
                <v-list-item-avatar size="54" v-if="business.logo">
                  <v-img :src="business.logo"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-2 font-weight-semi black--text">{{
                      business.business_name }}</v-list-item-title>
                  <v-list-item-subtitle class="text-caption blue--text">{{ business.tagline
                    }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="text-caption mt-1">{{ business.city }}, {{
                      business.state }}</v-list-item-subtitle>
                </v-list-item-content>

              </v-list-item>
            </v-list>
            <div class="text-caption mb-4 line-clamp">
              {{ business.description }}
            </div>
            <div>
              <v-chip v-for="(item, idx) in limitedServices" :key="idx" small outlined color="primary"
                      class="ma-1">
                {{ item.service_name }}
              </v-chip>
              <v-chip v-if="remainingServices > 0" small outlined color="grey" class="ma-1">
                +{{ remainingServices }} more
              </v-chip>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn small color="success" depressed v-if="business.verified" @click.stop="approve(0)" :loading="approving" :disabled="approving || disabled" class="video_approve_btn">
              APPROVED
            </v-btn>
            <v-btn small text color="success" outlined v-else  @click.stop="approve(1)" :loading="approving" :disabled="approving || disabled" class="video_approve_btn">
              APPROVE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <business-preview v-model="showBusinessPreview" :business="business" />

    </div>
</template>

<script>
import BusinessPreview from "@/views/components/BusinessPreview.vue";

export default {
    name: 'BusinessCard',
  components: {BusinessPreview},
    props: ['business'],
    data() {
        return {
            approving: false,
            updating: false,
            disabled: false,
            showBusinessPreview: false,
        }
    },
    computed: {
      limitedServices() {
        // Limit the services to 4 or 5
        return this.business.services.slice(0, 3);
      },
      remainingServices() {
        // Calculate the remaining services count
        return this.business.services.length - this.limitedServices.length;
      },
    },
    methods: {
      openBusinessPreview() {
        this.showBusinessPreview=true;
      },

        approve(val) {
            let business_id = this.business.xid;
            let approve = val

            this.approving = true;
            this.$store.dispatch('saveBusiness', {
                approve,
                business_id,
            }).then(() => {
                this.approving = false;
                this.business.verified = val
            })
                .catch(err => {
                    this.approving = false;
                });
        },
    }
}
</script>
