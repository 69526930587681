<template>
  <v-card class="my-4 mild-shadow">

    
        <v-card-title>
          <v-row>
            <v-col cols="6">
              <div>
      <v-list-item>
        <v-list-item-content  v-if="conversation.parties[0].pet">
          <v-list-item-title class="font-weight-medium">
            <span class="float-left">{{conversation.parties[0].pet.name}}</span>
            <v-icon small color="#d4af37" v-if="conversation.parties[0].pet.is_premium">mdi-crown</v-icon>
            <v-icon small color="#ff0000" v-if="conversation.parties[0].pet.user.banned">mdi-account-cancel</v-icon>
          </v-list-item-title>

          <v-list-item-subtitle class="text-caption secondary--text" v-html="conversation.parties[0].pet.breed"></v-list-item-subtitle>
          <!-- <v-list-item-subtitle v-if="conversation.parties[0].pet" class="text-caption" v-html="'For '+conversation.parties[0].pet.name"></v-list-item-subtitle> -->

        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-title class="font-weight-medium">
            <span class="float-left">{{conversation.parties[0].user.name}}</span>
            <v-icon small color="#d4af37" v-if="conversation.parties[0].user.is_premium">mdi-crown</v-icon>
            <v-icon small color="#ff0000"  v-if="conversation.parties[0].user.banned">mdi-account-cancel</v-icon>
          </v-list-item-title>
          <!-- <v-list-item-subtitle v-if="conversation.parties[0].pet" class="text-caption" v-html="'For '+conversation.parties[0].pet.name"></v-list-item-subtitle> -->
        </v-list-item-content>


      </v-list-item>

      <v-list-item class="rounded-lg" style="background-color: #e7f3ff ">
        <v-list-item-content style="margin:0">
          <v-list-item-subtitle v-if="conversation.parties[0].pet" class="text-caption info--text"><strong class="font-weight-medium">{{conversation.parties[0].pet.user.name}}</strong> <br/> {{conversation.parties[0].pet.user.email}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else class="text-caption info--text"><strong class="font-weight-medium">{{conversation.parties[0].user.name}}</strong> <br/> {{conversation.parties[0].user.email}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
      </v-col>
      <v-col cols="6">
        <div>
       
      <v-list-item style="text-align: right">
        <v-list-item-content v-if="conversation.parties[1].pet">
          <v-list-item-title class="font-weight-medium">
            <span class="float-right mr-1">{{conversation.parties[1].pet.name}}</span>
            <v-icon small color="#d4af37" v-if="conversation.parties[1].pet.is_premium">mdi-crown</v-icon>
            <v-icon small color="#ff0000" v-if="conversation.parties[1].pet.user.banned">mdi-account-cancel</v-icon>
          </v-list-item-title>

          <v-list-item-subtitle class="text-caption secondary--text" v-html="conversation.parties[1].pet.breed"></v-list-item-subtitle>
          <!-- <v-list-item-subtitle v-if="conversation.parties[1].pet" class="text-caption" v-html="'For '+conversation.parties[1].pet.name"></v-list-item-subtitle> -->
        </v-list-item-content>

        <v-list-item-content v-else>
          <v-list-item-title class="font-weight-medium">
            <span class="float-right mr-1">{{conversation.parties[1].user.name}}</span>
            <v-icon small color="#d4af37" v-if="conversation.parties[1].user.is_premium">mdi-crown</v-icon>
            <v-icon small color="#ff0000"  v-if="conversation.parties[1].user.banned">mdi-account-cancel</v-icon>
          </v-list-item-title>
          <!-- <v-list-item-subtitle v-if="conversation.parties[1].pet" class="text-caption" v-html="'For '+conversation.parties[1].pet.name"></v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="rounded-lg" style="background-color: #e7f3ff;text-align: right">
          <v-list-item-content style="margin:0">
            <v-list-item-subtitle v-if="conversation.parties[1].pet" class="text-caption info--text"><strong class="font-weight-medium">{{conversation.parties[1].pet.user.name}}</strong> <br/> {{conversation.parties[1].pet.user.email}}</v-list-item-subtitle>
            <v-list-item-subtitle v-else class="text-caption info--text"><strong class="font-weight-medium">{{conversation.parties[1].user.name}}</strong> <br/> {{conversation.parties[1].user.email}}</v-list-item-subtitle>
          </v-list-item-content>
      </v-list-item>
    </div>
      </v-col>
    </v-row>
  </v-card-title>

      <v-card-text>


          <v-row v-for="(item, index) in conversation.messages"
                 :key="index" :justify="item.fuid == conversation.parties[1].user.xid ? 'end' : 'start'">
            <v-col cols="10">
              <div class="text-caption pa-1" v-show="item.fuid != conversation.parties[1].user.xid">
                {{ item.created_at || new Date() | moment("D MMM YY, h:mm a") }}
                <span class="pl-2" v-if="item.verified && !item.read" @click="legendDialog = true">
                    <v-icon small color="green">mdi-check</v-icon>
                </span>
                <span class="pl-2" v-if="item.read" @click="legendDialog = true">
                    <v-icon small color="blue">mdi-check-all</v-icon>
                </span>
              </div>


              <v-sheet :color="item.fuid == conversation.parties[1].user.xid ? 'primary' : '#edf0f5'"
                       :dark="item.fuid == conversation.parties[1].user.xid"
                       :class="item.fuid == conversation.parties[1].user.xid ? 'rounded-br-0 rounded-xl pa-4' : 'rounded-tl-0 rounded-xl pa-4'">
                <span class="text-pre-wrap" v-if="item.message_type == 'text'">{{ item.message }}</span>
                <v-img v-else class="ml-auto rounded-xl" max-height="200px" :src="item.photo.photo" cover aspect-ratio="1"></v-img>
              </v-sheet>

              <div class="text-caption text-right pa-1" v-show="item.fuid == conversation.parties[1].user.xid">
                {{ item.created_at || new Date() | moment("D MMM YY, h:mm a") }}

                <span class="pl-2" v-if="item.verified && !item.read" @click="legendDialog = true">
                    <v-icon small color="green">mdi-check</v-icon>
                </span>
                <span class="pl-2" v-if="item.read" @click="legendDialog = true">
                    <v-icon small color="blue">mdi-check-all</v-icon>
                </span>
              </div>
            </v-col>
          </v-row>
      </v-card-text>
      <v-card-text>

        <v-alert v-if="conversation.direct_connect" text dense class="text-caption mb-0" color="info">
             <v-icon class="pr-2" small color="info">mdi-phone-ring</v-icon> Parties connected via Direct Connect
            </v-alert>

            <v-alert v-if="conversation.explanation" text dense class="text-caption mb-0" :color="conversation.followup_status=='rejected' ? 'error' : 'success'">
              <p><strong>Analysis: </strong>{{ conversation.followup_status }}</p>
              <strong>Explanation: </strong>{{ conversation.explanation }}
            </v-alert>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="success" :loading="updating" :disabled="updating" class="col-3" style="margin-right: 5px" @click="followupConversation(conversation)">Follow Up</v-btn>
        <v-btn v-if="conversation.followup_status !== 'reviewed'" small color="info" :loading="reviewing" :disabled="reviewing"  class="col-4" @click="markAsReviewed(conversation)">Mark As Reviewed</v-btn>
        <v-btn v-else small color="info" class="col-3">Reviewed</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
export default {
  name: 'AnalyseConversationCard',
  props: ['conversation'],
  data() {
    return {
      conversations: {},
      updating: false,
      reviewing: false,
      loading: false,
      page: 0,
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    followupConversation(conversation) {
      let conversation_id = conversation.xid;

      this.updating = true;
      this.$store.dispatch('followupConversation', {
        conversation_id,
      }).then(() => {
        this.updating = false;
      })
          .catch(err => {
            this.updating = false;
          });
    },
    markAsReviewed(conversation) {
      let conversation_id = conversation.xid;

      this.reviewing = true;
      this.$store.dispatch('markConversationAsReviewed', {
        conversation_id,
      }).then(() => {
        this.reviewing = false;
      })
      .catch(err => {
        this.reviewing = false;
      });
    },
  }
}
</script>
