import Vue from 'vue'
import VueRouter from 'vue-router'
import Axios from 'axios'
import store from '../store'


import Home from '../views/Home.vue'
import Descriptions from '../views/Descriptions.vue'
import Feedback from '../views/Feedback.vue'
import Messages from '../views/Messages.vue'
import Names from '../views/Names.vue'
import Photos from '../views/Photos.vue'
import Reports from '../views/Reports.vue'
import Premium from '../views/Premium.vue'
import Favorites from '../views/Favorites.vue'
import ScamReporting from '../views/ScamReporting.vue'
import Videos from "../views/Videos.vue";
import ProfileVideos from "../views/ProfileVideos.vue";
import Certificates from "../views/Certificates.vue";
import DnaReports from "../views/DnaReports.vue";
import UserDetails from "../views/UserDetails.vue";
import SearchUser from "../views/SearchUser.vue";
import FavoriteVideos from "../views/FavoriteVideos.vue";
import InstagramFollowers from "../views/FollowOnInstagram.vue";
import TwitterFollowers from "../views/FollowOnTwitter.vue";
import UserPhotos from "../views/UserPhotos.vue";
import UserNames from "../views/UserNames.vue";
import AdopterQuestionnaire from "../views/AdopterQuestionnaire.vue";
import UserProfileVideos from "../views/UserProfileVideos.vue";
import PreferenceDescriptions from "../views/PreferenceDescriptions.vue";
import BreederQuestionnaire from "../views/BreederQuestionnaire.vue";
import ShelterQuestionnaire from "../views/ShelterQuestionnaire.vue";
import PetInfoVerifications from "@/views/PetInfoVerifications.vue";
import AiTextAudit from "@/views/AiTextAudit.vue";
import Businesses from "@/views/Businesses.vue";
import AnalyseConversations from "@/views/AnalyseConversations.vue";
//import EditPet from "../views/EditPet.vue";



Vue.use(VueRouter)

const routes = [

  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login.vue')
  },

  {
    path: '/',
    component: Home,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/descriptions',
    name: 'Descriptions',
    component: Descriptions,
    meta:
    {
        requiresAuth: true,
    },
  },
  {
    path: '/pet-info-verifications',
    name: 'PetInfoVerifications',
    component: PetInfoVerifications,
    meta:
    {
        requiresAuth: true,
    },
  },
  {
    path: '/preference-descriptions',
    name: 'PreferenceDescriptions',
    component: PreferenceDescriptions,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/adopter-questionnaire',
    name: 'AdopterQuestionnaire',
    component: AdopterQuestionnaire,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/breeder-questionnaire',
    name: 'BreederQuestionnaire',
    component: BreederQuestionnaire,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/shelter-questionnaire',
    name: 'ShelterQuestionnaire',
    component: ShelterQuestionnaire,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/user-profile-videos',
    name: 'UserProfileVideos',
    component: UserProfileVideos,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/favorites',
    name: 'Favorites',
    component: Favorites,
    meta:
        {
          requiresAuth: true,
        },
  },


  {
    path: '/favorite-videos',
    name: 'FavoriteVideos',
    component: FavoriteVideos,
    meta:
        {
          requiresAuth: true,
        },
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/messages',
    name: 'Messages',
    component: Messages,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/names',
    name: 'Names',
    component: Names,
    meta:
    {
        requiresAuth: true,
    },
  },

    {
        path: '/user-names',
        name: 'UserNames',
        component: UserNames,
        meta:
            {
                requiresAuth: true,
            },
    },

  {
    path: '/photos',
    name: 'Photos',
    component: Photos,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/user-photos',
    name: 'UserPhotos',
    component: UserPhotos,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/videos',
    name: 'Videos',
    component: Videos,
    meta:
        {
          requiresAuth: true,
        },
  },
  {
    path: '/ai-text-audit',
    name: 'AiTextAudit',
    component: AiTextAudit,
    meta:
        {
          requiresAuth: true,
        },
  },
  {
    path: '/businesses',
    name: 'Businesses',
    component: Businesses,
    meta:
        {
          requiresAuth: true,
        },
  },
  {
    path: '/profile-videos',
    name: 'ProfileVideos',
    component: ProfileVideos,
    meta:
        {
          requiresAuth: true,
        },
  },

  {
    path: '/certificates',
    name: 'Pedigree Certificate',
    component: Certificates,
    meta:
        {
          requiresAuth: true,
        },
  },

  {
    path: '/dna-reports',
    name: 'DNA Reports',
    component: DnaReports,
    meta:
        {
          requiresAuth: true,
        },
  },

  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/premium',
    name: 'Premium',
    component: Premium,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/scam-reportings',
    name: 'ScamReporting',
    component: ScamReporting,
    meta:
        {
          requiresAuth: true,
        },
  },

  {
    path: '/instagram-followers',
    name: 'InstagramFollowers',
    component: InstagramFollowers,
    meta:
        {
          requiresAuth: true,
        },
  },

  {
    path: '/twitter-followers',
    name: 'TwitterFollowers',
    component: TwitterFollowers,
    meta:
        {
          requiresAuth: true,
        },
  },

  {
    path: '/user-details',
    name: 'UserDetails',
    component: UserDetails,
    props: route => ({ xid: route.query.xid }),
    meta:
        {
          requiresAuth: true,
        },
  },
 /*  {
    path: '/editpet',
    name: 'EditPet',
    component: EditPet,
    props: route => ({ xid: route.query.xid }),
    meta:
        {
          requiresAuth: true,
        },
  }, */
  {
    path: '/search-user',
    name: 'SearchUser',
    component: SearchUser,
    props: route => ({ email: route.query.email }),
    meta: {
        requiresAuth: true,
    },
  },
  {
    path: '/analyse-conversations',
    name: 'AnalyseConversations',
    component: AnalyseConversations,
    meta: {
        requiresAuth: true,
    },
  },



// otherwise redirect to home
{ path: '*', redirect: '/home' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {

    //document.getElementById('app').scrollIntoView();

    const fromHistory = Boolean(savedPosition);

    if (fromHistory && store.state.routerHistory.length > 0) {
      store.state.routerHistory.splice(-1, 1);
    } else {
      store.state.routerHistory.push(from);
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(savedPosition || { x: 0, y: 0 })
      }, 60)
    })

    /* console.log(savedPosition);
    //return savedPosition || { x: 0, y: 0 };

    if (savedPosition) {
      console.log('scrolled')
      return savedPosition
    } else {
      console.log('start')
      return { x: 0, y: 0 }
    }
 */

    },
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/terms','/privacy','/landing'];
  const loggedIn = store.getters.isLoggedIn;
  const authRequired = to.matched.some(route => route.meta.requiresAuth)

  if(publicPages.includes(to.path))
  {
      return next();
  }

  if (authRequired && !loggedIn) {
    return next('/login');
  }
  else if (!authRequired && loggedIn) {
      return next();
  }
  next();
});



Axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
if (typeof error.response != "undefined") {
  if (typeof error.response.status != "undefined") {
      if (error.response.status == 401) {
          store.dispatch('logout');
          router.replace('/login').catch(err => {});
      }
      if (error.response.status == 403) {
          router.push('/home');
      }
      if (error.response.status == 402) {
          router.push('/home');
      }
  }
}

  return Promise.reject(error)

});


export default router
