<template>
<v-container fluid class="home">

    <div v-if="this.$store.getters.userDetails.name == '' || loading" class="pt-12">

        <v-row no-gutters>
            <v-col cols="12" justify="center" align="center" class="pt-12">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>

        </v-row>

    </div>
    <div v-else class="mb-16">

        <div class="my-2 mb-4">
            <v-row no-gutters>
                <v-col cols="12">
                    <h4 class="text-h6">Businesses</h4>
                </v-col>
            </v-row>

        </div>
        <v-row v-if="this.$store.getters.businesses.length == 0">
            <v-col cols="12" class="pa-12 text-center">
                <v-icon size="120" color="#eee">mdi-thumb-up-outline</v-icon>
                <div>Well Done!!</div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="4" lg="4" xl="3" v-for="(item,index) in this.$store.getters.businesses" :key="index" class="pa-2">
                <business-card :business="item"></business-card>
            </v-col>
        </v-row>
    </div>

    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

    <v-btn color="primary" dark fixed bottom right fab @click="getBusinesses">
        <v-icon>mdi-sync</v-icon>
    </v-btn>

</v-container>
</template>

<script>
import BusinessCard from './components/BusinessCard.vue'
export default {
    name: 'Businesses',
    mounted() {

        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login').catch(err => {});
        } else {
            this.getBusinesses();
        }

    },
    data() {
        return {
            loading: false,
            snackbar: false,
            snackbar_text: '',
        }
    },
    components: {
      BusinessCard,
    },
    methods: {

        getBusinesses() {
            this.loading = true;
            this.$store.dispatch('getBusinesses').then(() => {
                    this.loading = false;
                })
                .catch(err => {
                    //console.log(err)
                    this.loading = false;
                    this.snackbar_text = "Oops, Couldn't get data. Please try again.";
                    this.snackbar = true;
                });
        }
    }

}
</script>
