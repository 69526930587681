<template>
  <v-row no-gutters>
    <v-col cols="12" class="pt-5 text-center" v-if=" $store.getters.analyseConversations.length == 0">
      <h4>User do not have any conversations</h4>
      </v-col>

      <v-col cols="12" sm="6" md="6" lg="4" xl="3" v-else v-for="(conversation, index) in $store.getters.analyseConversations" :key="index" class="pa-2">
        <analyse-conversation-card :conversation="conversation"></analyse-conversation-card>
      </v-col>

      <v-btn color="primary" dark fixed bottom right fab @click="getAnalyseConversations" :loading="loading">
        <v-icon>mdi-sync</v-icon>
      </v-btn>
    </v-row>

</template>

<script>
import AnalyseConversationCard from "@/views/components/AnalyseConversationCard.vue";

export default {
  name: 'AnalyseConversations',
  components: {AnalyseConversationCard},
  data() {
    return {
      conversations: {},
      updating: false,
      reviewing: false,
      loading: false,
      page: 0,
    }
  },
  mounted() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push('/login').catch(err => { });
    } else {
      this.getAnalyseConversations();
    }
  },
  computed: {
  },
  methods: {
    getAnalyseConversations() {
      this.loading = true;
      let page = this.page + 1;
      this.$store.dispatch('getAnalyseConversations', page).then(() => {
        this.loading = false;
        this.page = page;
      }).catch(err => {
        this.loading = false;
        this.snackbar_text = "Oops, Couldn't get data. Please try again.";
        this.snackbar = true;
      });
    },
  }
}
</script>
